var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "single-question" }, [
    _c("label", { staticClass: "col-lg-12 control-lable font-weight-bold" }, [
      _vm._v(_vm._s(_vm.item.name)),
    ]),
    _vm.hasDescription
      ? _c("label", { staticClass: "col-lg-12 control-label" }, [
          _vm._v(_vm._s(_vm.item.description)),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "col-lg-8" },
      [
        _vm.item.shape === _vm.likerTypes.Slider
          ? [
              _c(
                "div",
                { staticClass: "survey-range-group" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.minValue))]),
                  _c("b-form-input", {
                    staticClass: "ml-3 mr-3",
                    attrs: {
                      type: "range",
                      min: _vm.minValue,
                      max: _vm.maxValue,
                    },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.maxValue))]),
                ],
                1
              ),
            ]
          : [
              _c("b-form-rating", {
                attrs: { stars: _vm.maxValue, "min-value": 0 },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }