var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "single-question" }, [
    _c("label", { staticClass: "col-lg-12 control-lable font-weight-bold" }, [
      _vm._v(_vm._s(_vm.item.name)),
    ]),
    _vm.hasDescription
      ? _c("label", { staticClass: "col-lg-12 control-label" }, [
          _vm._v(_vm._s(_vm.item.description)),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "col-lg-8" },
      [
        _c(
          "multiselect",
          {
            class: [_vm.state === false ? "is-invalid" : ""],
            attrs: {
              "track-by": "value",
              label: "description",
              options: _vm.options,
              "open-direction": "bottom",
              placeholder: _vm.$t("surveys.selectOption"),
              "clear-on-select": true,
              "show-no-results": true,
              "show-no-options": false,
              multiple: true,
              "show-labels": false,
              "close-on-select": false,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          },
          [
            _c("template", { slot: "noResult" }, [
              _c("span", { staticClass: "multiselect__single" }, [
                _vm._v(_vm._s(_vm.$t("trainingScheduler.select.noResults"))),
              ]),
            ]),
            _c("template", { slot: "noOptions" }, [
              _c("span", { staticClass: "multiselect__single" }, [
                _vm._v(_vm._s(_vm.$t("trainingScheduler.select.noOptions"))),
              ]),
            ]),
          ],
          2
        ),
        _vm.state === false
          ? _c("div", { staticClass: "invalid-feedback" }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }