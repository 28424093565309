



























import { SurveyItemType } from '@/shared/enums/survey-item-type.enum';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { SurveyItem, SurveyQuestionGroup } from '../../models/survey-question-group.model';

import SingleSurveyQuestion from './SingleSurveyQuestion.vue';

@Component({
    components: {
        'single-survey-question': SingleSurveyQuestion
    }
})
export default class QuestionGroup extends Vue {

    item!: SurveyItem;

    @Getter
    currentQuestionGroup!: SurveyQuestionGroup;
    @Getter
    surveyStarted!: boolean;
    @Getter
    surveyFinnished!: boolean;

    @Action
    private nextQuestionGroup!: () => Promise<void>
    @Action
    private peviousQuestionGroup!: () => Promise<void>
    @Action
    private finnishSurvey!: () => Promise<void>

    async created() : Promise<void> {
        this.item = this.currentQuestionGroup.item;
    }

    get isQuestionGroup() : boolean {
        return this.item.itemType === SurveyItemType.Group;
    }

    get itemTypes() {
        return SurveyItemType;
    }

    async saveAndGoToNextGroup() : Promise<void> {
        try {
            await this.nextQuestionGroup();
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, this.$t('surveys.errorOccured'));
        }
    }

    async goToPreviousGroup() : Promise<void> {
        try {
            await this.peviousQuestionGroup();
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, this.$t('surveys.errorOccured'));
        }
    }

    async onFinnishSurveyClick() : Promise<void> {
        try {
            await this.finnishSurvey();
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, this.$t('surveys.errorOccured'));
        }
    }

    @Watch('surveyFinnished')
    async surveyHasFinnished() {
        if (this.surveyFinnished) {
            
            ToastHelper.showSuccess(this.$bvToast, this.$t('surveys.surveyFinishedSuccesfully'));

            // LX-7168: with next task here we can redirect user to event signup 
            // (when eventId and eventToken are not null)

            this.$router.push('/').catch(ex => ex);
        }
    }
}
