var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question-group-container" },
    [
      !_vm.isQuestionGroup
        ? [
            _c("single-survey-question", {
              attrs: { "item-type": _vm.item.itemType, item: _vm.item },
            }),
          ]
        : _vm._l(_vm.item.surveyItems, function (groupItem) {
            return _c("single-survey-question", {
              key: groupItem.id,
              attrs: { "item-type": _vm.item.itemType, item: groupItem },
            })
          }),
      _c(
        "b-row",
        { staticClass: "question-group-buttons col-lg-12 mt-4" },
        [
          _c(
            "b-col",
            { staticClass: "text-left" },
            [
              _vm.currentQuestionGroup.hasPreviousGroup
                ? _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "outline-success" },
                      on: { click: _vm.goToPreviousGroup },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("surveys.previousQuestionGroup"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _vm.currentQuestionGroup.hasNextGroup
                ? _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.saveAndGoToNextGroup },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("surveys.nextQuestionGroup"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.currentQuestionGroup.hasNextGroup && _vm.surveyStarted
                ? _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.onFinnishSurveyClick },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("surveys.finnishQuestionGroup"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }