var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      _vm.item.type === _vm.questionType.Int
        ? _c("question-int", {
            attrs: { item: _vm.item, "group-type": _vm.itemType },
          })
        : _vm.item.type === _vm.questionType.Date
        ? _c("question-date", {
            attrs: { item: _vm.item, "group-type": _vm.itemType },
          })
        : _vm.item.type === _vm.questionType.Bool
        ? _c("question-bool", {
            attrs: { item: _vm.item, "group-type": _vm.itemType },
          })
        : _vm.item.type === _vm.questionType.Liker
        ? _c("question-liker", {
            attrs: { item: _vm.item, "group-type": _vm.itemType },
          })
        : _vm.item.type === _vm.questionType.Text
        ? _c("question-text", {
            attrs: { item: _vm.item, "group-type": _vm.itemType },
          })
        : _vm.item.type === _vm.questionType.SingleSelectList
        ? _c("question-single-select", {
            attrs: { item: _vm.item, "group-type": _vm.itemType },
          })
        : _vm.item.type === _vm.questionType.MultiSelectList
        ? _c("question-multi-select", {
            attrs: { item: _vm.item, "group-type": _vm.itemType },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }