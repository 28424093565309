






















import { SurveyItemType } from '@/shared/enums/survey-item-type.enum';
import { SurveyQuestionLikerShape } from '@/shared/enums/survey-question-liker-shape.enum';
import { SurveyQuestionType } from '@/shared/enums/survey-question-type.enum';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { SurveyItem } from '../../models/survey-question-group.model';

@Component
export default class QuestionTypeLiker extends Vue {
    @Prop() item!: SurveyItem;
    @Prop() groupType!: SurveyItemType;

    @Action
    private setAnwearValue!: (payload: { id: string, groupType: SurveyItemType, variableType: SurveyQuestionType, value: any }) => void

    public get value() : any | null{
        return this.item.intValue;
    }

    public set value(val: any | null) {
        this.setAnwearValue({ 
            id: this.item.id, 
            groupType: this.groupType, 
            variableType: SurveyQuestionType.Int, 
            value: val === null ? null : Number(val)
        });
    }

    get minValue(): number {
        return this.item.minValue ?? 0;
    }

    get maxValue(): number {
        return this.item.maxValue ?? 10;
    }

    get hasDescription() : boolean {
        return this.item.description !== null && this.item.description !== '';
    }

    get likerTypes() : any {
        return SurveyQuestionLikerShape;
    }
}
