var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "single-question" }, [
    _c("label", { staticClass: "col-lg-12 control-lable font-weight-bold" }, [
      _vm._v(_vm._s(_vm.item.name)),
    ]),
    _vm.hasDescription
      ? _c("label", { staticClass: "col-lg-12 control-label" }, [
          _vm._v(_vm._s(_vm.item.description)),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "col-lg-8" },
      [
        _c("b-form-checkbox", {
          attrs: { required: _vm.item.isRequired },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }