




























































import { SurveyRespondentStatus } from '@/shared/enums/survey-respondent-status.enum';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { FillSurveyClub, FillSurveyResponse } from '../models/fill-survey-response.model';
import QuestionGroup from './components/QuestionGroup.vue';

@Component({
    components: {
        'question-group': QuestionGroup
    }
})
export default class FillSurveyPage extends Vue {
    loading = false;    

    @Action
    private reset!: () => Promise<void>;
    @Action
    private fetchSurvey!: (surveyToken: string) => Promise<void>;
    @Action
    private startSurvey!: (eventId: string | null, eventToken: string | null) => Promise<void>;
    @Action
    private resumeSurvey!: (eventId: string | null, eventToken: string | null) => Promise<void>;

    @Getter
    surveyInfo!: FillSurveyResponse | null;
    @Getter
    questionGroupId!: string | null;
    @Getter
    clubInfo!: FillSurveyClub | null;
    @Getter
    questionGroupLoading!: boolean;

    get surveyToken() : string {
        return this.$route.params.token;
    }

    get eventId() : string | null {
        if (!this.$route.query.event) {
            return null;
        }
        return this.$route.query.event[0];
    }

    get eventToken() : string | null {
        if (!this.$route.query.eventToken) {
            return null;
        }
        return this.$route.query.eventToken[0];
    }

    get showStartSurvey() : boolean {
        return this.surveyInfo?.respondent.status === SurveyRespondentStatus.Invited;
    }

    get showResumeSurvey() : boolean {
        return this.surveyInfo?.respondent.status === SurveyRespondentStatus.Started;
    }

    get backgroundImageStyle() : { backgroundImage: string, backgroundSize: string } {
        let imgSrc = '';

        if (this.loading) {
            imgSrc = '';
        }
        else if (this.clubInfo?.backgroundImage !== null && this.clubInfo?.backgroundImage !== '') {
            imgSrc = 'url(' + this.clubInfo?.backgroundImage + ')';
        }
        else {
            imgSrc = 'url(\'/bg2.jpg\')';
        }

        return {
            backgroundImage: imgSrc,
            backgroundSize: 'cover'
        };
    }

    async created() : Promise<void> {
        await this.reset();

        if (this.surveyToken) {
            await this.getSurvey();
        }
    }

    async getSurvey() : Promise<void> {
        try {
            this.loading = true;

            await this.fetchSurvey(this.surveyToken);
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, `${this.$t('surveys.errorOccured')}`)
        }
        finally {
            this.loading = false;
        }
    }

    async start() : Promise<void> {
        try {

            await this.startSurvey(this.eventId, this.eventToken);
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, `${this.$t('surveys.errorOccured')}`)
        }
    }

    async resume() : Promise<void> {
        try {

            await this.resumeSurvey(this.eventId, this.eventToken);
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, `${this.$t('surveys.errorOccured')}`)
        }
    }

    @Watch('surveyToken')
    async onTokenChange() : Promise<void> {
        await this.reset();

        if (this.surveyToken) {
            await this.getSurvey();
        }
    }
}
