











import { SurveyItemType } from '@/shared/enums/survey-item-type.enum';
import { SurveyQuestionType } from '@/shared/enums/survey-question-type.enum';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { SurveyItem } from '../../models/survey-question-group.model';

@Component
export default class QuestionTypeText extends Vue {
    @Prop() item!: SurveyItem;
    @Prop() groupType!: SurveyItemType;

    @Action
    private setAnwearValue!: (payload: { id: string, groupType: SurveyItemType, variableType: SurveyQuestionType, value: any }) => void

    public get value() : any | null{
        return this.item.textValue;
    }

    public set value(val: any | null) {
        this.setAnwearValue({ 
            id: this.item.id, 
            groupType: this.groupType, 
            variableType: SurveyQuestionType.Text, 
            value: val
        });
    }

    get hasDescription() : boolean {
        return this.item.description !== null && this.item.description !== '';
    }
    
    get errorMessage() : string | null {
        return this.item.state.errorMessage;
    }

    get state(): boolean | null {
        if (this.item.state.isValid !== null) {
            return false;
        }

        return null;
    }
}
