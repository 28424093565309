










import { SurveyItemType } from '@/shared/enums/survey-item-type.enum';
import { SurveyQuestionType } from '@/shared/enums/survey-question-type.enum';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { SurveyItem } from '../../models/survey-question-group.model';

@Component
export default class QuestionTypeBool extends Vue {
    @Prop() item!: SurveyItem;
    @Prop() groupType!: SurveyItemType;

    @Action
    private setAnwearValue!: (payload: { id: string, groupType: SurveyItemType, variableType: SurveyQuestionType, value: any }) => void

    public get value() : boolean | null{
        return this.item.boolValue;
    }

    public set value(val: boolean | null) {
        this.setAnwearValue({ 
            id: this.item.id, 
            groupType: this.groupType, 
            variableType: SurveyQuestionType.Bool, 
            value: val
        });
    }

    get hasDescription() : boolean {
        return this.item.description !== null && this.item.description !== '';
    }
}
