var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "backend", style: [_vm.backgroundImageStyle] },
    [
      _c("main", [
        _c("div", { staticClass: "container" }, [
          !_vm.surveyToken
            ? _c("div", { staticClass: "loading-container" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("surveys.surveyNotFound")))]),
              ])
            : _vm.loading
            ? _c("div", { staticClass: "loading-container" }, [_c("Loader")], 1)
            : _vm.surveyInfo
            ? _c("div", { staticClass: "main-content" }, [
                _c(
                  "div",
                  { staticClass: "club-info" },
                  [
                    _c(
                      "b-img",
                      _vm._b(
                        {
                          attrs: {
                            rounded: "circle",
                            src: _vm.clubInfo.logoUrl,
                          },
                        },
                        "b-img",
                        {
                          blank: _vm.clubInfo.logoUrl === null,
                          blankColor: "#777",
                          width: 100,
                          height: 100,
                          class: "m1 club-logo",
                        },
                        false
                      )
                    ),
                    _c("span", { staticClass: "club-name" }, [
                      _vm._v(_vm._s(_vm.clubInfo.name)),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "divider" }),
                _c("div", { staticClass: "row text-left" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "b-overlay",
                        {
                          attrs: {
                            show: _vm.questionGroupLoading,
                            rounded: "sm",
                          },
                        },
                        [
                          _c(
                            "b-card",
                            {
                              attrs: {
                                title: _vm.surveyInfo.survey.name,
                                "body-class": "text-left",
                              },
                            },
                            [
                              !_vm.questionGroupId
                                ? [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.surveyInfo.survey.description
                                        ),
                                      },
                                    }),
                                    _vm.surveyInfo.startsInFuture
                                      ? _c(
                                          "div",
                                          { staticClass: "w-100 text-center" },
                                          [
                                            _c("span", [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "surveys.surveyInFutrue"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        )
                                      : _vm.surveyInfo.surveyHasEnded
                                      ? _c(
                                          "div",
                                          { staticClass: "w-100 text-center" },
                                          [
                                            _c("span", [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "surveys.surveyHasEnded"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        )
                                      : _vm.surveyInfo.surveyIsCompleted
                                      ? _c(
                                          "div",
                                          { staticClass: "w-100 text-center" },
                                          [
                                            _c("span", [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "surveys.surveyCompleted"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        )
                                      : _vm.showStartSurvey ||
                                        _vm.showResumeSurvey
                                      ? _c(
                                          "div",
                                          { staticClass: "buttons-section" },
                                          [
                                            _vm.showStartSurvey
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant: "success",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.start()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "surveys.startSurvey"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm.showResumeSurvey
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant: "success",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.resume()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "surveys.resumeSurvey"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : [
                                    _c("question-group", {
                                      key: _vm.questionGroupId,
                                    }),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }