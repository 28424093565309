












import { SurveyItemType } from '@/shared/enums/survey-item-type.enum';
import { SurveyQuestionType } from '@/shared/enums/survey-question-type.enum';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SurveyItem } from '../../models/survey-question-group.model';

import QuestionTypeBool from './QuestionTypeBool.vue';
import QuestionTypeDate from './QuestionTypeDate.vue';
import QuestionTypeInt from './QuestionTypeInt.vue';
import QuestionTypeLiker from './QuestionTypeLiker.vue';
import QuestionTypeSingleSelect from './QuestionTypeSingleSelect.vue';
import QuestionTypeMultiSelect from './QuestionTypeMultiSelect.vue';
import QuestionTypeText from './QuestionTypeText.vue';

@Component({
    components: {
        'question-bool': QuestionTypeBool,
        'question-date': QuestionTypeDate,
        'question-int': QuestionTypeInt,
        'question-liker': QuestionTypeLiker,
        'question-multi-select': QuestionTypeMultiSelect,
        'question-single-select': QuestionTypeSingleSelect,
        'question-text': QuestionTypeText
    }
})
export default class SingleSurveyQuestion extends Vue {
    
    @Prop() itemType!: SurveyItemType;
    @Prop() item!: SurveyItem;

    get variableTypes() {
        return SurveyQuestionType;
    }    

    get questionType() {
        return SurveyQuestionType;
    }
}
